import generalConfig from '@/config/general';
export default {
  createdAt: 1732923485,
  updatedAt: 1732923485,
  slug: 'gauge-chart-maker',
  heading: 'Gauge Chart Maker',
  title: 'Online Gauge Chart Maker - Free, No Signup',
  description: 'Create stunning gauge charts in seconds: upload your spreadsheet or sync with Google Drive. Completely free. No sign-up required.',
  shortDescription: 'Create beautiful gauge charts quickly.  Modify data, customize gauge design, and share.',
  directory: 'Charts',
  directoryUrl: '/charts',
  icon: '/icons/chart-gauge.svg',
  basicDataEditorColumnStructure: {
    labelColumn: 'Label',
    subColumns: [{
      label: 'Value'
    }],
    repeatSubColumns: false
  },
  images: {
    resourceUrl: '/img/pages/gauge-chart-maker/featured-image.webp',
    resourceUrlAlt: 'Area chart showing company profits for each data next to a spreadsheet selection',
    twitterPost: '/img/pages/gauge-chart-maker/twitter-post.webp',
    openGraph: '/img/pages/gauge-chart-maker/og-image.webp'
  },
  reviews: [],
  articleSections: [{
    id: 'what-is-a-gauge-chart',
    name: 'What Is a Gauge Chart?'
  }, {
    id: 'why-are-gauge-charts-beneficial',
    name: 'Why Are Gauge Charts Beneficial?'
  }, {
    id: 'when-to-use-a-gauge-chart',
    name: 'When to Use a Gauge Chart'
  }, {
    id: 'how-to-create-a-gauge-chart',
    name: 'How to Create a Gauge Chart'
  }, {
    id: 'how-to-make-a-gauge-chart-in-excel',
    name: 'How to Make a Gauge Chart in Excel'
  }, {
    id: 'how-to-make-a-gauge-chart-in-google-sheets',
    name: 'How to Make a Gauge Chart in Google Sheets'
  }, {
    id: 'free-gauge-chart-maker',
    name: `${generalConfig.productName} Free Gauge Chart Maker`
  }]
};