import generalConfig from '@/config/general';
export default {
  createdAt: 1738865572,
  updatedAt: 1738865572,
  slug: 'radar-chart-maker',
  heading: 'Radar Chart Maker',
  title: 'Online Radar Chart Maker - Free, No Signup',
  description: 'Quickly design professional radar charts quickly with our free, zero-sign-up tool. Connect data from Excel, Google Sheets, or Google Drive.',
  shortDescription: 'Design beautiful radar charts quickly and easily with this free, zero-sign-up tool.',
  directory: 'Charts',
  directoryUrl: '/charts',
  icon: '/icons/chart-radar.svg',
  basicDataEditorColumnStructure: {
    labelColumn: 'Labels',
    subColumns: [{
      label: 'Layer'
    }]
  },
  images: {
    resourceUrl: '/img/pages/radar-chart-maker/featured-image.webp',
    resourceUrlAlt: 'Radar chart comparing revenue by quarter for past 2 years, next to a spreadsheet popout',
    twitterPost: '/img/pages/radar-chart-maker/twitter-post.webp',
    openGraph: '/img/pages/radar-chart-maker/og-image.webp'
  },
  reviews: [],
  articleSections: [{
    id: 'what-is-a-radar-chart',
    name: 'What is a Radar Chart?'
  }, {
    id: 'difference-between-radar-and-spider-chart',
    name: 'What is the Difference Between a Radar Chart and a Spider Chart?'
  }, {
    id: 'when-to-use-a-radar-chart',
    name: 'When to Use a Radar Chart?'
  }, {
    id: 'how-to-create-your-own-radar-chart',
    name: 'How to Create Your Own Radar Chart'
  }, {
    id: 'how-to-make-a-radar-chart-in-excel',
    name: 'How to Make a Radar Chart in Excel'
  }, {
    id: 'how-to-make-a-radar-chart-in-google-sheets',
    name: 'How to Make a Radar Chart in Google Sheets'
  }, {
    id: 'free-radar-chart-maker',
    name: `${generalConfig.productName} Free Radar Chart Maker`
  }]
};