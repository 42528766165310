import generalConfig from '@/config/general';
export default {
  createdAt: 1689750000,
  updatedAt: 1739552884,
  slug: 'donut-chart-maker',
  heading: 'Donut Chart Maker',
  title: 'Online Donut Chart Maker - Free, No Signup',
  description: 'Free browser based tool to create a doughnut or pie chart. Import your Excel & Google Sheets. Customize everything, then download as PNG, JPG or WEBP',
  shortDescription: 'Create a free donut chart. Import data from Excel & Google Sheets. Customize then download below.',
  directory: 'Charts',
  directoryUrl: '/charts',
  basicDataEditorColumnStructure: {
    labelColumn: 'Labels',
    subColumns: [{
      label: 'Ring'
    }]
  },
  images: {
    resourceUrl: '/img/pages/donut-chart-maker/featured-image.webp',
    resourceUrlAlt: 'Doughnut chart surrounded by a mathmatical grid and circle',
    twitterPost: '/img/pages/donut-chart-maker/twitter-post.webp',
    openGraph: '/img/pages/donut-chart-maker/og-image.webp'
  },
  icon: '/icons/chart-donut.svg',
  reviews: [],
  articleSections: [{
    id: 'what-is-a-donut-chart',
    name: 'What is a Donut Chart?'
  }, {
    id: 'what-is-the-difference-between-a-donut-chart-and-a-ring-chart',
    name: 'What Is the Difference Between a Donut Chart and a Ring Chart?'
  }, {
    id: 'why-are-donut-charts-useful',
    name: 'Why Are Donut Charts Useful?'
  }, {
    id: 'when-to-use-a-doughnut-chart',
    name: 'When to Use a Doughnut Chart?'
  }, {
    id: 'how-to-create-a-donut-chart-online',
    name: 'How to Create a Donut Chart Online'
  }, {
    id: 'how-to-make-a-doughnut-chart-in-excel',
    name: 'How to Make a Doughnut Chart in Excel'
  }, {
    id: 'how-to-create-a-doughnut-chart-in-google-sheets',
    name: 'How to Create a Doughnut Chart in Google Sheets'
  }, {
    id: 'free-doughnut-chart-maker',
    name: `${generalConfig.productName} Free Doughnut Chart Maker`
  }]
};