import generalConfig from '@/config/general';
export default {
  createdAt: 1730726810,
  updatedAt: 1737155138,
  slug: 'line-graph-maker',
  heading: 'Line Graph Maker',
  title: 'Online Line Graph Maker - Free, No Signup',
  description: 'Quickly design professional line charts quickly with our free, zero-sign-up tool. Connect data from Excel, Google Sheets, or Google Drive.',
  shortDescription: 'Design beautiful line charts quickly and easily with this free, zero-sign-up tool.',
  directory: 'Charts',
  directoryUrl: '/charts',
  icon: '/icons/chart-line-graph.svg',
  templatesEnabled: true,
  templatesTag: 'line chart',
  basicDataEditorColumnStructure: {
    labelColumn: 'Labels',
    subColumns: [{
      label: 'Line'
    }]
  },
  images: {
    resourceUrl: '/img/pages/line-graph-maker/featured-image.webp',
    resourceUrlAlt: 'Line chart showing revenue for the data next to a spreadsheet popout',
    twitterPost: '/img/pages/line-graph-maker/twitter-post.webp',
    openGraph: '/img/pages/line-graph-maker/og-image.webp'
  },
  reviews: [],
  articleSections: [{
    id: 'what-is-a-line-chart',
    name: 'What Is a Line Chart?'
  }, {
    id: 'when-to-use-a-line-chart',
    name: 'When to Use a Line Chart'
  }, {
    id: 'what-are-the-different-types-of-line-charts',
    name: 'What are the Different Types of Line Charts?'
  }, {
    id: 'how-do-i-create-my-own-line-chart',
    name: 'How Do I Create My Own Line Chart?'
  }, {
    id: 'how-to-make-a-line-chart-in-excel',
    name: 'How to Make a Line Chart in Excel'
  }, {
    id: 'how-to-make-a-line-chart-in-google-sheets',
    name: 'How to Make a Line Chart in Google Sheets'
  }, {
    id: 'business-ready-line-chart-maker',
    name: `${generalConfig.productName}: The Business-Ready Line Chart Maker`
  }]
};