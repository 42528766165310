import generalConfig from '@/config/general';
export default {
  createdAt: 1731523637,
  updatedAt: 1733774540,
  slug: 'stacked-bar-graph-maker',
  heading: 'Stacked Bar Graph Maker',
  title: 'Online Stacked Bar Graph Maker - Free, No Signup',
  description: 'Effortlessly create customized stacked bar charts for free. Simply upload XLSX files sync from Google Drive and generate stunning charts instantly.',
  shortDescription: 'Download stacked bar charts for free. Upload your data, customize the design, then share.',
  directory: 'Charts',
  directoryUrl: '/charts',
  icon: '/icons/chart-stacked-bar.svg',
  images: {
    resourceUrl: '/img/pages/stacked-bar-graph-maker/featured-image.webp',
    resourceUrlAlt: 'Stacked bar chart showing sales by product category next to a spreadsheet, google drive, and sheets logos',
    twitterPost: '/img/pages/stacked-bar-graph-maker/twitter-post.webp',
    openGraph: '/img/pages/stacked-bar-graph-maker/og-image.webp'
  },
  reviews: [],
  basicDataEditorColumnStructure: {
    labelColumn: 'Labels',
    subColumns: [{
      label: 'Values'
    }]
  },
  articleSections: [{
    id: 'what-is-a-stacked-bar-graph',
    name: 'What is a Stacked Bar Graph?'
  }, {
    id: 'what-is-the-difference-between-a-stacked-bar-graph-maker-and-a-segmented-bar-graph-maker',
    name: 'What is the Difference Between a Stacked Bar Graph Maker and a Segmented Bar Graph Maker?'
  }, {
    id: 'what-are-stacked-bar-graphs-used-for',
    name: 'What are Stacked Bar Graphs Used For?'
  }, {
    id: 'how-to-create-a-stacked-bar-graph',
    name: 'How to Create a Stacked Bar Graph'
  }, {
    id: 'how-to-make-a-stacked-bar-graph-in-excel',
    name: 'How to Make a Stacked Bar Graph in Excel'
  }, {
    id: 'how-to-make-a-stacked-bar-graph-in-google-sheets',
    name: 'How to Make a Stacked Bar Graph in Google Sheets'
  }, {
    id: 'free-stacked-bar-graph-maker',
    name: `${generalConfig.productName}: Free Stacked Bar Graph Maker`
  }]
};